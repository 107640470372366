<template>
  <div v-if="singleData.signed" class="view content-padding contract-parties">
    <div class="row">
      <div class="col-12">
        <div
          v-if="singleData.signed.length"
          :class="{
            'contract-parties-list': true,
            'contract-parties-dynamic': true,
          }"
        >
          <div
            v-for="signatory in singleData.signed"
            :key="signatory.user._id"
            class="contract-parties-list-partie"
          >
            <span>
              {{ signatory.user.username }}
            </span>
            <span class="text-right">
              {{ labels.signed }} - {{ formatDate(signatory.dateSigned) }}
            </span>
          </div>
        </div>
        <div v-else class="text-center contract-parties-list">
          {{ labels.no_users }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import colorsHtml from "/colors.config.json";

export default {
  name: "ContractSignatories",
  components: {},
  data() {
    return {
      colorsHtml: colorsHtml
    }
  },
  computed: {
    ...mapGetters("helper", ["labels"]),
    ...mapState("contract", {
      singleData: "singleData",
    })
  },
  methods: {
    openUserProfile(id, kind) {
      this.$router.push({
        name: 'edit-user',
        params: {
          userId: id,
          kind: kind
        }
      })
    },
    formatDate(isoDate) {
      const date = new Date(isoDate);
      const year = date.getFullYear();

      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }

      if (month < 10) {
        month = "0" + month;
      }

      return `${dt}.${month}.${year}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.contract-parties {
  &-dynamic {
    transition: all 300ms ease-out;
  }

  &-list {
    border: 2px solid $background;
    padding: $column_top_margin;

    &-partie {
      width: 100%;
      margin-top: $column_top_margin;
      display: flex;
      align-items: center;

      span {
        display: inline-block;
        flex: 1 0 auto;
        margin-right: 15px;
        word-break: break-word;
      }
    }

    &-partie:first-child {
      margin-top: 0px;
    }
  }
}
</style>
